export default {
  props: {
    item: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {};
  },
  methods: {}
};