import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.sort.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "series-add"
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("a-form-model", {
    ref: "form",
    attrs: {
      model: _vm.formData,
      rules: _vm.formRules,
      layout: "vertical",
      labelAlign: "left"
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      gutter: 24,
      align: "bottom"
    }
  }, [_c("a-col", {
    staticStyle: {
      "min-width": "540px",
      "max-width": "710px"
    },
    attrs: {
      span: 14
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "系列创建人",
      prop: "userid"
    }
  }, [_c("a-select", {
    staticClass: "user-select",
    attrs: {
      size: "large",
      dropdownClassName: "user-select",
      "show-search": "",
      placeholder: "选择创作者",
      "not-found-content": _vm.uloading ? undefined : null,
      "filter-option": false,
      disabled: !!_vm.seriesId
    },
    on: {
      search: _vm.handleUserSearch
    },
    model: {
      value: _vm.formData.userid,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "userid", $$v);
      },
      expression: "formData.userid"
    }
  }, [_vm.uloading ? _c("a-spin", {
    attrs: {
      slot: "notFoundContent",
      size: "small"
    },
    slot: "notFoundContent"
  }) : _vm._e(), _vm._l(_vm.userSource, function (d) {
    return _c("a-select-option", {
      key: d.uid,
      staticClass: "user-select-item",
      attrs: {
        title: d.username,
        value: d.uid
      }
    }, [_c("img", {
      staticClass: "user-select-avatar",
      attrs: {
        src: d.headimgurl
      }
    }), _c("span", [_vm._v(_vm._s(d.username))])]);
  })], 2)], 1), _c("a-form-model-item", {
    attrs: {
      label: "系列排序",
      prop: "sort"
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      disabled: !_vm.formData.id
    },
    on: {
      click: _vm.showModalDrag
    }
  }, [_vm._v("调整系列排序")])], 1), _c("a-form-model-item", {
    attrs: {
      label: "系列名称",
      prop: "name"
    }
  }, [_c("a-input", {
    staticClass: "series-name",
    attrs: {
      placeholder: "请输入系列名称",
      maxLength: 20
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  }, [_c("span", {
    staticStyle: {
      background: "transparent",
      color: "$color-e7"
    },
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(_vm._s(_vm.formData.name.length) + " / 20")])])], 1), _c("a-form-model-item", {
    staticStyle: {
      position: "relative"
    },
    attrs: {
      label: "系列描述",
      prop: "memo"
    }
  }, [_c("a-textarea", {
    staticStyle: {
      width: "500px",
      "max-width": "500px"
    },
    attrs: {
      autoSize: {
        minRows: 4,
        maxRows: 6
      },
      maxLength: 200,
      placeholder: "请输入系列描述"
    },
    model: {
      value: _vm.formData.memo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "memo", $$v);
      },
      expression: "formData.memo"
    }
  }), _c("span", {
    staticClass: "memo-limit"
  }, [_vm._v(_vm._s(_vm.formData.memo.length) + " / 200")])], 1)], 1), _c("a-col", {
    attrs: {
      span: 9,
      push: 1
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "系列封面",
      prop: "coverurl"
    }
  }, [_c("uploadPicture", {
    attrs: {
      classname: _vm.uploadProps.classname,
      listType: _vm.uploadProps.listType,
      memorySize: _vm.uploadProps.memorySize,
      showTips: _vm.uploadProps.showTips,
      minPx: _vm.uploadProps.minPx,
      accept: _vm.uploadProps.accept
    },
    model: {
      value: _vm.formData.coverurl,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "coverurl", $$v);
      },
      expression: "formData.coverurl"
    }
  })], 1)], 1)], 1), _c("a-row", {
    staticStyle: {
      "max-width": "980px"
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "系列内作品 " + _vm.worksItems.length,
      prop: "items"
    }
  }, [_c("div", {
    staticClass: "list-box"
  }, [_vm.worksItems.length ? _c("a-button", {
    staticClass: "btn-add",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addWorks
    }
  }, [_vm._v("添加")]) : _vm._e(), _vm.worksItems.length ? _c("draggable", {
    attrs: {
      animation: "300"
    },
    on: {
      sort: _vm.worksSortChange
    },
    model: {
      value: _vm.worksItems,
      callback: function callback($$v) {
        _vm.worksItems = $$v;
      },
      expression: "worksItems"
    }
  }, _vm._l(_vm.worksItems, function (item, index) {
    return _c("videoItem", {
      key: item.id,
      attrs: {
        item: item,
        sortIndex: item.sort || index + 1
      },
      on: {
        remove: _vm.removeWorks,
        showDrag: _vm.showModalDrag
      }
    });
  }), 1) : _c("div", {
    staticClass: "content-empty",
    on: {
      click: _vm.addWorks
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "32px"
    },
    attrs: {
      type: "plus"
    }
  }), _c("p", {
    staticClass: "explain"
  }, [_vm._v("点击添加已通过的作品至当前系列")])], 1)], 1)])], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "添加",
      visible: _vm.modalVisible,
      footer: null,
      width: "756px"
    },
    on: {
      cancel: function cancel($event) {
        _vm.modalVisible = false;
      }
    }
  }, [_c("a-input-search", {
    attrs: {
      placeholder: "关键词/作品ID查询已选创建人的作品",
      loading: _vm.searchLoading
    },
    on: {
      search: _vm.searchHandler
    }
  }, [_c("span", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  })]), _c("div", {
    staticClass: "scroll-list J_scroll",
    on: {
      scroll: _vm.scrollPage
    }
  }, [_vm.allVideoList.length ? _vm._l(_vm.allVideoList, function (item, index) {
    return _c("videoItem", {
      key: index,
      attrs: {
        mode: "add",
        item: item
      },
      on: {
        add: _vm.addHandler
      }
    });
  }) : _c("a-empty")], 2)], 1), _c("DPopup", {
    attrs: {
      title: _vm.modalDragTitle,
      comVisible: _vm.modalDrag,
      isClosable: true,
      modalWidth: "600px"
    },
    on: {
      cancel: function cancel($event) {
        _vm.modalDrag = false;
      }
    }
  }, [_c("div", {
    attrs: {
      slot: "form"
    },
    slot: "form"
  }, [_c("draggable", {
    staticClass: "works-sort",
    attrs: {
      delay: 50,
      animation: "300"
    },
    on: {
      end: _vm.dragEnd
    },
    model: {
      value: _vm.dragData,
      callback: function callback($$v) {
        _vm.dragData = $$v;
      },
      expression: "dragData"
    }
  }, [_vm.dragData.length ? _vm._l(_vm.dragData, function (item) {
    return _c("seriesItem", {
      key: item.id,
      attrs: {
        item: item
      }
    });
  }) : _c("a-empty")], 2), _c("a-button", {
    staticClass: "btn-save",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveSort
    }
  }, [_vm._v("保存")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };