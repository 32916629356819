import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.array.splice.js";
import previewMixin from "@/utils/previewMixin";
import debounce from "lodash/debounce";
import uploadPicture from './components/uploadPicture';
import videoItem from './components/videoItem';
import { WhitelistApi } from "@/api";
import TableMixin from "@/utils/tableMixin";
import SeriesV2Api from '@/api/seriesV2';
import infiniteScroll from "vue-infinite-scroll";
import draggable from "vuedraggable";
import { dateFormat } from "@/utils/dateutil";
import seriesItem from './components/seriesItem';
export default {
  name: 'seriesV2Add',
  mixins: [previewMixin, TableMixin],
  directives: {
    infiniteScroll: infiniteScroll
  },
  components: {
    uploadPicture: uploadPicture,
    videoItem: videoItem,
    draggable: draggable,
    seriesItem: seriesItem
  },
  data: function data() {
    return {
      seriesId: '',
      // 系列id，存在即说明是编辑
      loading: false,
      timer: null,
      userSource: [],
      // 创作者列表
      uloading: false,
      formData: {
        userid: '',
        sort: 1,
        // 默认排序为1
        name: '',
        memo: '',
        coverurl: '',
        items: []
      },
      formRules: {
        userid: [{
          required: true,
          message: '选择创作者',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请输入系列名称'
        }],
        coverurl: [{
          required: true,
          message: '请上传系列封面',
          trigger: 'blur'
        }]
      },
      worksItems: [],
      // 系列内作品集合
      uploadProps: {
        classname: 'upload-sty',
        listType: 'picture-card',
        memorySize: String(5 * 1024),
        minPx: [100, 100],
        // 最小像素
        showTips: false,
        accept: '.png, .jpg, .jpeg' // 文件类型
      },

      allVideoList: [],
      // 所有的可选作品
      delVideoList: [],
      // 手动移除的作品，因为移除只是前端的操作，后台接口数据没变，导致所有可选作品里面没有增加新删除的作品

      modalVisible: false,
      searchLoading: false,
      searchKey: '',
      busy: false,
      page: 1,
      size: 10,
      total: 0,
      pageEnd: false,
      // 没有数据了

      dragData: [],
      modalDragTitle: '系列排序',
      modalDrag: false
    };
  },
  created: function created() {
    this.seriesId = this.$route.query.id || '';
  },
  mounted: function mounted() {
    this.initUserSource();
    if (this.seriesId) this.getDetail();
  },
  watch: {
    allVideoList: function allVideoList() {
      var _this = this;
      this.worksItems.map(function (val) {
        _this.allVideoList.map(function (value) {
          if (val.id == value.id) _this.$set(value, 'add_series', true); // 自定义字段，标识作品是否已在系列内
        });
      });
    },

    'worksItems.length': function worksItemsLength() {
      var _this2 = this;
      var arr = [];
      this.allVideoList.map(function (val) {
        if (_this2.worksItems.some(function (value) {
          return val.id == value.id;
        })) {
          _this2.$set(val, 'add_series', true);
        } else {
          _this2.$set(val, 'add_series', false);
        }
      });
      this.worksItems.map(function (val, idx) {
        arr.push({
          videoid: val.id,
          sort: idx + 1
        });
      });
      // console.log(arr);
      this.formData.items = arr;
    }
  },
  methods: {
    // 创作者搜索
    handleUserSearch: function handleUserSearch(val) {
      var _this3 = this;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.userSource = [];
      if (!val) return;
      this.uloading = true;
      this.timer = setTimeout(function () {
        WhitelistApi.searchUsername({
          name: val
        }).then(function (res) {
          _this3.userSource = res;
          _this3.uloading = false;
        }).catch(function () {
          _this3.uloading = false;
        });
      }, 500);
    },
    // 初始化用户数据
    initUserSource: function initUserSource() {
      var creators = window.localStorage.getItem(this.creatorKey);
      if (creators) {
        creators = JSON.parse(creators);
      } else {
        creators = [];
      }
      creators.sort(function (a, b) {
        return b.num - a.num;
      });
      this.userSource = creators.slice(0, 10);
    },
    // 获取系列详情
    getDetail: function getDetail() {
      var _this4 = this;
      SeriesV2Api.detail({
        id: this.seriesId
      }).then(function (res) {
        var _cloneObj$videolist;
        // console.log(res);
        var cloneObj = JSON.parse(JSON.stringify(res)) || {};
        var arr = [];
        (_cloneObj$videolist = cloneObj.videolist) === null || _cloneObj$videolist === void 0 ? void 0 : _cloneObj$videolist.map(function (val) {
          arr.push({
            videoid: val.id,
            sort: val.sort
          });
        });
        cloneObj.items = arr;
        _this4.worksItems = cloneObj.videolist || [];
        delete cloneObj.collectioncount;
        delete cloneObj.forwardcount;
        delete cloneObj.updatetime;
        delete cloneObj.videolist;
        _this4.formData = cloneObj;
        _this4.userSource = [{
          uid: cloneObj.userid,
          username: cloneObj.username
        }];
      }).catch(function (err) {});
    },
    // 顶部按钮点击
    pageBtnClicked: function pageBtnClicked(module, action) {
      // console.log(module, action);
      if (action == 'other') this.submitData();
      if (action == 'reset') {
        if (this.seriesId) {
          this.getDetail();
        } else {
          this.$refs.form.resetFields();
        }
      }
    },
    // 所有作品搜索
    searchHandler: function searchHandler(value) {
      this.searchKey = value;
      this.busy = false;
      this.page = 1;
      this.total = 0;
      this.allVideoList = [];
      this.getWorksList();
    },
    // 所有作品搜索
    getWorksList: function getWorksList() {
      var _this5 = this;
      this.busy = true;
      SeriesV2Api.worksList({
        key: this.searchKey,
        userid: this.formData.userid,
        page: this.page,
        size: this.size
      }).then(function (res) {
        var _res$list;
        res === null || res === void 0 ? void 0 : res.list.map(function (val) {
          val.createtime = dateFormat(val.createtime, 'yyyy/MM/dd hh:mm:ss');
        });
        _this5.allVideoList = _this5.allVideoList.concat(res.list || []);
        // console.log(res);
        _this5.page++;
        _this5.total = res.total;
        _this5.busy = false;
        if (!((_res$list = res.list) !== null && _res$list !== void 0 && _res$list.length)) _this5.pageEnd = true;
        if (_this5.delVideoList.length) {
          var del = [];
          _this5.delVideoList.map(function (value) {
            !_this5.allVideoList.some(function (val) {
              return val.id == value.id;
            }) && del.push(value);
          });
          _this5.allVideoList = _this5.allVideoList.concat(del);
        }
      }).catch(function (err) {
        return console.log(err);
      });
    },
    showModalDrag: function showModalDrag() {
      if (!this.formData.id) return this.toast('系列创建完成方可调整排序', 'error');
      this.modalDrag = true;
      this.getSeriesList();
    },
    // 系列拖拽
    dragEnd: function dragEnd(e) {
      var _this6 = this;
      // console.log(this.dragData);
      this.dragData.map(function (val, index) {
        _this6.$set(val, 'sort', index + 1);
      });
    },
    // 作品拖拽
    worksSortChange: function worksSortChange(e) {
      var _this7 = this;
      // console.log('sort,', this.worksItems);
      var arr = [];
      this.worksItems.map(function (val, index) {
        arr.push({
          videoid: val.id,
          sort: index + 1
        });
        _this7.$set(val, 'sort', index + 1);
      });
      this.formData.items = arr;
    },
    // 新增
    submitData: function submitData() {
      var _this8 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this8.seriesId) {
            delete _this8.formData.sort;
            SeriesV2Api.edit(_this8.formData).then(function (res) {
              // console.log(res);
              _this8.toast("修改成功", "success");
              _this8.$router.go(-1);
            });
          } else {
            SeriesV2Api.add(_this8.formData).then(function (res) {
              // console.log(res);
              _this8.toast("添加成功", "success");
              _this8.$router.go(-1);
            }).catch(function (err) {
              return console.log(err);
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 打开添加弹窗
    addWorks: function addWorks() {
      if (!this.formData.userid) {
        this.$refs.form.validateField('userid');
        return;
      }
      this.page = 1;
      this.searchKey = '';
      this.modalVisible = true;
      this.pageEnd = false;
      this.allVideoList = [];
      this.getWorksList();
    },
    // 作品滚动分页
    scrollPage: function scrollPage() {
      if (this.busy) return;
      var scrollHeight = document.getElementsByClassName('J_scroll')[0].scrollHeight; //document的滚动高度即文档总高度
      var nowScotop = document.getElementsByClassName('J_scroll')[0].clientHeight; //可视区高度
      var scrollTop = document.getElementsByClassName('J_scroll')[0].scrollTop; //已滚动高度即顶部被卷起来的高度
      if (scrollHeight - scrollTop < 2 * nowScotop && !this.pageEnd) {
        this.getWorksList();
      }
    },
    // 添加作品到系列
    addHandler: function addHandler(value) {
      // console.log(value);
      value.status = value.isstatus; // 2个接口关于状态的字段定义不一样
      this.worksItems.unshift(value);
      // this.modalVisible = false;
    },
    // 作品移除
    removeWorks: function removeWorks(id) {
      var Index = '';
      this.worksItems.map(function (value, index) {
        if (value.id == id) Index = index;
      });
      var del = this.worksItems.splice(Index, 1);
      var flag = this.delVideoList.some(function (val) {
        return val.id == del[0].id;
      });
      if (!flag && del[0].status < 2) this.delVideoList.push(del[0]); // status 大于1表示状态为驳回或者已删除
    },
    // 获取用户所有系列
    getSeriesList: function getSeriesList() {
      var _this9 = this;
      SeriesV2Api.seriesUser({
        uid: this.formData.userid
      }).then(function (res) {
        res.map(function (val) {
          val.createtime = dateFormat(val.createtime, 'yyyy/MM/dd hh:mm:ss');
        });
        _this9.dragData = res || [];
      });
    },
    // 系列顺序更改保存
    saveSort: function saveSort() {
      var _this10 = this;
      var list = [];
      this.dragData.map(function (val) {
        list.push({
          seriesid: val.id,
          sort: val.sort
        });
      });
      SeriesV2Api.changeSort({
        uid: this.formData.userid,
        list: list
      }).then(function (res) {
        _this10.toast('修改成功', 'success');
        _this10.modalDrag = false;
      });
    }
  }
};