var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "video-item",
    class: _vm.className
  }, [_c("div", {
    staticClass: "item-wrap"
  }, [_c("p", {
    staticClass: "sort"
  }, [_vm._v(_vm._s(_vm.mode != "add" ? _vm.sortIndex : ""))]), _c("img", {
    staticClass: "item-cover",
    attrs: {
      src: _vm.item.coverurl,
      alt: ""
    }
  }), _c("div", {
    staticClass: "item-info"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.item.title)), _c("span", {
    staticClass: "vid"
  }, [_vm._v("（作品ID：" + _vm._s(_vm.item.id) + "）")])]), _c("p", {
    staticClass: "play"
  }, [_c("span", [_vm._v("播放：" + _vm._s(_vm.item.playcount))]), _c("span", [_vm._v("Up-vote：" + _vm._s(_vm.item.voteup))]), _c("span", [_vm._v("评论：" + _vm._s(_vm.item.commentcount))])]), _c("p", {
    staticClass: "create-time"
  }, [_c("span", [_vm._v("发布时间：" + _vm._s(_vm.item.createtime))]), _vm.mode != "add" ? _c("span", [_vm._v("状态："), _c("span", {
    class: _vm.item.status > 1 ? "color-red" : "color-green"
  }, [_vm._v(" " + _vm._s(_vm.item.status == 0 ? "待审核" : _vm.item.status == 1 ? "审核通过" : _vm.item.status == 2 ? "驳回" : "已删除") + " ")])]) : _vm._e()])])]), _vm.mode != "add" ? _c("div", [_c("a", {
    staticClass: "drag",
    attrs: {
      href: "javascript:void(0);"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("showDrag");
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "swap"
    }
  }), _vm._v(" 拖拽调整排序 ")], 1), _c("a-button", {
    staticClass: "del-btn",
    attrs: {
      type: "danger"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("remove", _vm.item.id);
      }
    }
  }, [_vm._v("删除")])], 1) : _c("div", [!_vm.item.add_series ? _c("a-icon", {
    staticClass: "icon-add",
    attrs: {
      type: "plus-square"
    },
    on: {
      click: _vm.addHandler
    }
  }) : _c("span", {
    staticClass: "text-added"
  }, [_vm._v("已添加")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };