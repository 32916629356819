var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "clearfix",
    class: _vm.classname
  }, [_c("a-upload", {
    attrs: {
      accept: _vm.accept,
      listType: _vm.listType,
      beforeUpload: _vm.beforeUpload,
      customRequest: _vm.handleUpload,
      showUploadList: _vm.disabled ? {
        showRemoveIcon: false,
        showPreviewIcon: false
      } : _vm.showUploadList,
      fileList: _vm.fileList,
      disabled: _vm.disabled
    },
    on: {
      change: _vm.handleChange,
      preview: _vm.previewImg
    }
  }, [_vm._t("default", function () {
    return [!_vm.fileList.length ? _c("div", {
      staticClass: "empty-limit"
    }, [_c("img", {
      attrs: {
        src: require("@/assets/svgs/upload_yun.svg"),
        alt: ""
      }
    }), _c("div", {
      staticClass: "ant-upload-text"
    }, [_vm._v("文件大小不超过5M，分辨率至少为100*100，1080*1080最佳")])]) : _vm._e()];
  })], 2), _vm.showTips ? _c("div", {
    staticClass: "img_rules"
  }, [_vm.memorySize ? _c("div", {
    staticClass: "suport_text"
  }, [_vm._v(_vm._s(_vm.force ? "" : "建议") + "大小: " + _vm._s(_vm.force ? "" : "<") + _vm._s(_vm.ByteConvert(_vm.imgSize * 1024)))]) : _vm._e(), _vm.imgProportion.length ? _c("div", {
    staticClass: "suport_text"
  }, [_c("span", [_vm._v(_vm._s(_vm.force ? "" : "建议") + "尺寸: " + _vm._s(_vm.imgProportion[0] + "*" + _vm.imgProportion[1]) + " ")])]) : _vm._e()]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };