import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "sort-item"
  }, [_c("span", {
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.item.sort))]), _c("img", {
    staticClass: "img",
    attrs: {
      src: _vm.item.coverurl,
      alt: ""
    }
  }), _c("div", {
    staticClass: "reset"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.item.name))]), _c("p", {
    staticClass: "time"
  }, [_vm._v("创建时间：" + _vm._s(_vm.item.createtime))])]), _c("a", {
    staticClass: "drag-icon",
    attrs: {
      href: "javascript:void(0);"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "swap"
    }
  }), _vm._v(" 拖拽调整排序 ")], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };