import "core-js/modules/es.number.constructor.js";
export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: {}
    },
    mode: {
      type: String,
      default: 'show' // show 首页展示， add 所有作品筛选弹窗
    },

    sortIndex: {
      type: String | Number,
      default: ''
    }
  },
  computed: {
    className: function className() {
      var str = '';
      if (this.mode == 'add') str += 'mode-add ';
      return str;
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    addHandler: function addHandler() {
      this.$emit('add', this.item);
    }
  }
};